import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InAppBrowser  } from '@ionic-native/in-app-browser/ngx';
import { GoogleMaps } from '@ionic-native/google-maps';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

// Providers
import { AppDataProvider } from './providers/app-data/app-data';
import { GlobalProvider } from './providers/global/global';
import { NavigationService } from './providers/navigation.service';
import { RestInterceptor } from './providers/rest.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RestInterceptor,
      multi: true
    },
    AppDataProvider,
    GlobalProvider,
    NavigationService,
    InAppBrowser,
    GoogleMaps,
    PhotoViewer,
    SocialSharing
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
