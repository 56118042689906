import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  { path: 'contact', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: 'detail', loadChildren: './pages/detail/detail.module#DetailPageModule' },
  { path: 'detail/:favourites', loadChildren: './pages/detail/detail.module#DetailPageModule' },
  { path: 'families', loadChildren: './pages/families/families.module#FamiliesPageModule' },
  { path: 'families/:store', loadChildren: './pages/families/families.module#FamiliesPageModule' },
  { path: 'favourites', loadChildren: './pages/favourites/favourites.module#FavouritesPageModule' },
  { path: 'materials', loadChildren: './pages/materials/materials.module#MaterialsPageModule' },
  { path: 'materials/:favourites', loadChildren: './pages/materials/materials.module#MaterialsPageModule' },
  { path: 'material-assistant', loadChildren: './pages/material-assistant/material-assistant.module#MaterialAssistantPageModule' },
  { path: 'stocks', loadChildren: './pages/stocks/stocks.module#StocksPageModule' },
  { path: 'stocks/:favourites', loadChildren: './pages/stocks/stocks.module#StocksPageModule' },
  { path: 'stores', loadChildren: './pages/stores/stores.module#StoresPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
