import { Injectable } from '@angular/core';
import { Material } from '../models/material.model';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    erpCodes: Array<string>;
    materials: Array<Material>;

    reset() {
        console.log("Resetting NavigationService");
        this.erpCodes = [];
        this.materials = [];
    }
}