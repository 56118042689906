import { environment } from './environments/environment';
export class Constants {
    public static get APP_VERSION(): string {
        return "2.1.6";
    }
    public static get GMAPS_ANDROID_API_KEY(): string {
        return 'AIzaSyCPI8OZhiKFhlBWqEZK92HfC_NLptB2-2E';
    }
    public static get GMAPS_JS_API_KEY(): string {
        return 'AIzaSyCRW308iOSV5Rv4_ndYyNxSELuuCVxmWv8';
    }
    public static get DEFAULT_LATITUDE(): number {
        return 43.420625199999996;
    }
    public static get DEFAULT_LONGITUDE(): number {
        return -5.8229391;
    }
}