import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from 'src/app/models/store.model';
import { Stock } from 'src/app/models/stock.model';
import { Material } from 'src/app/models/material.model';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({ providedIn: 'root' })
export class GlobalProvider {
  // Stores
  public stores: Array<Store>;
  public selectedStore: Store;

  // Families
  public selectedFamilyTitle: string;
  public selectedFamilyId: number;

  // Materials
  public selectedMaterial: Material;
  public selectedMaterialId: number;
  public selectedMaterialCode: string;
  public selectedMaterialTitle: string;
  public selectedMaterialStore: string;

  // Stocks
  public selectedStock: Stock;
  public selectedStockId: number;
  public selectedStockCode: string;
  public selectedStockTitle: string;
  public selectedStockMeters: number;
  public selectedStockPieces: number;
  public selectedStockThumbnail: string;
  public selectedStockImage: string;

  // Settings
  public enableMaps: boolean;
  public screenWidth: number;

  // Settings
  public enableStorage: boolean;
  public enableSocialSharing: boolean;
  
  constructor(public http: HttpClient) {
  }

}
