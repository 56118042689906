import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions  } from '@ionic-native/in-app-browser/ngx';

import { Store } from 'src/app/models/store.model';
import { AppDataProvider } from 'src/app/providers/app-data/app-data';
import { GlobalProvider } from 'src/app/providers/global/global';
import { NavigationService } from 'src/app/providers/navigation.service';
import { async } from 'q';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [];
  //   {
  //     id: 1,
  //     title: 'Almacenes',
  //     url: null,
  //     icon: 'ico-store',
  //     css: 'firstlevel',
  //     parent: null,
  //     display: true,
  //     hasChildren: true,
  //     open: false
  //   },
  //   {
  //     id: 0,
  //     title: 'Almacén Alicante',
  //     url: '/home',
  //     icon: 'time',
  //     css: 'secondlevel',
  //     parent: 1,
  //     display: true,
  //     hasChildren: false,
  //     open: false
  //   },
  //   {
  //     id: 0,
  //     title: 'Almacén Baleares',
  //     url: '/home',
  //     icon: 'create',
  //     css: 'secondlevel',
  //     parent: 1,
  //     display: true,
  //     hasChildren: false,
  //     open: false
  //   }, {
  //     id: 2,
  //     title: 'Favoritos',
  //     url: 'favourites',
  //     icon: 'ico-favourite',
  //     css: 'firstlevel',
  //     parent: null,
  //     display: true,
  //     hasChildren: false,
  //     open: false
  //   }, {
  //     id: 3,
  //     title: 'Contacto',
  //     url: 'contact',
  //     icon: 'ico-call',
  //     css: 'firstlevel',
  //     parent: null,
  //     display: true,
  //     hasChildren: false,
  //     open: false
  //   }
  // ];

  selectedMenuOption = 0;

  items: Array<Store>;
  stores: any;

  constructor(
    public appDataProvider: AppDataProvider,
    public global: GlobalProvider,
    public navService: NavigationService,
    private router: Router,
    public menuController: MenuController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private inAppBrowser: InAppBrowser
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.createMenu();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  createMenu() {
    this.appPages.push(
      {
        id: 1,
        title: 'Stock Centers',
        url: null,
        icon: 'ico-store',
        css: 'firstlevel',
        parent: null,
        display: true,
        hasChildren: true,
        open: false,
        param: ''
      });


    this.appDataProvider.getStores('list')
    .then(data => {
      this.stores = data;
      this.items = new Array<Store>();

      this.global.stores = this.stores.map((item) =>  item );

      this.stores.forEach(store => {
        this.appPages.push(
        {
          id: 0,
          title: store.title,
          url: '/families',
          icon: 'time',
          css: 'secondlevel',
          parent: 1,
          display: true,
          hasChildren: false,
          open: false,
          param: store.id
        });

        this.items.push({
          id: store.id,
          code: store.code,
          title: store.title,
          address: store.address,
          address2: store.address2,
          phone: store.phone,
          latitude: store.latitude,
          longitude: store.longitude,
          additional: store.additional
        });
      });


    this.appPages.push(
      {
        id: 2,
        title: 'Favoritos',
        url: '/materials/favourites',
        icon: 'ico-favourite',
        css: 'firstlevel',
        parent: null,
        display: true,
        hasChildren: false,
        open: false,
        param: ''
      });

      this.appPages.push(
        {
          id: 3,
          title: 'Recomendador<br>de materiales',
          url: '/material-assistant',
          icon: 'ico-assistant',
          css: 'firstlevel',
          parent: null,
          display: true,
          hasChildren: false,
          open: false,
          param: ''
        });

    this.appPages.push(
      {
        id: 4,
        title: 'Contacto',
        url: 'contact',
        icon: 'ico-call',
        css: 'firstlevel',
        parent: null,
        display: true,
        hasChildren: false,
        open: false,
        param: ''
      });
    });


  }

  /**
   * Evaluates menu events
   * @param menuId the parent menu to toggle
   * @param url usually null, if set, the primary option is interpreted as a direct link to the given url and menu is hidden
   */
  toggleMenu(menuId, url, param) {
    if (url != null) {
      console.log('param', param);

      if (param !== '') {
        this.global.selectedStore = this.items.find((item) => item.id === param);
      }

      this.menuController.toggle();
      this.router.navigateByUrl(url); // Hide menu on logout

    } else {
      if (this.selectedMenuOption === menuId) {
        this.selectedMenuOption = 0;
      } else {
        this.selectedMenuOption = menuId;
      }
    }
  }

  openInBrowser(url) {
    const target = '_system';
    const options: InAppBrowserOptions  = {
      location : 'no'
    };
    this.inAppBrowser.create(url, target, options);
  }
}
