import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from "../../../constants";

import { GlobalProvider } from 'src/app/providers/global/global';
import { Material } from 'src/app/models/material.model';
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class AppDataProvider {
  apiUrl = '';

  dataTmp: any;

  favourites: any;

  constructor(
    public http: HttpClient,
    public global: GlobalProvider
    ) {
    this.apiUrl = environment.REST_API_URL;
  }

  getStore(id: any) {
    return this.global.stores.find((itm) => itm.id == id );

  }

  getStores(queryType) {
    var endpoint = this.apiUrl;
    endpoint += 'stores?queryType=' + queryType;
    // console.log('Calling ' + this.apiUrl + endpoint);

    return new Promise(resolve => {

      this.http.get(endpoint).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getFamilies(storeId) {
    var endpoint = this.apiUrl;
    endpoint += 'families';
    endpoint += '?storeId=' + storeId;
    console.log('Calling ' + endpoint+ ', ' + storeId);

    return new Promise(resolve => {
      // this.http.get(this.apiUrl+'/users').subscribe(data => {
      this.http.get(endpoint).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getMaterials(familyId, storeId) {
    var endpoint = this.apiUrl;
    endpoint += 'materials';
    endpoint += '?storeId=' + storeId + '&familyId=' + familyId;

    return new Promise(resolve => {
      this.http.get(endpoint).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getStocks(materialId, storeId) {
    console.log(materialId, storeId);
    var endpoint = this.apiUrl;
    endpoint += 'stocks';
    endpoint += '?storeId=' + storeId + '&materialId=' + materialId;

    return new Promise(resolve => {
      this.http.get(endpoint).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  resolveFavourites(favourites, storeCode) {
    var endpoint = this.apiUrl;
    endpoint += 'favourites';
    //endpoint += '?action=get&storeCode=' + storeCode + '&favourites=' + favourites;
    console.log('Calling ', endpoint);

    return new Promise(resolve => {
      let requestOptions = this.getRequestOptions();

      this.http.post(
        endpoint,
        JSON.stringify(
        {
          action: "get",
          storeCode: storeCode,
          favourites: favourites
        }
        ),
        requestOptions
      ).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  search(searchText) {
    var endpoint = this.apiUrl;
    endpoint += 'favourites';
    endpoint += '?action=search&searchText=' + encodeURI(searchText);
    console.log('Calling ', endpoint);

    return new Promise(resolve => {
      this.http.get(endpoint).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });
  }

  getFavourites() {
    if (this.favourites === undefined) {
      this.favourites = new Array<Material>();
      const favouritesLST = localStorage.getItem('favourites');
      if (favouritesLST !== null) {
        this.favourites = JSON.parse(favouritesLST);
      }
    }
    return this.favourites;
  }

  isFavourite(item: any) {
    this.getFavourites();
    if (this.favourites !== null ) {
      return this.favourites.find((itm) =>  ((itm.code === item.code) && (itm.store_id === item.store_id) && (itm.record_type === item.record_type)) ) !== undefined;
    }
    return false;
  }

  changeFavourite(item) {
    item.isFavourite = !item.isFavourite;

    this.getFavourites();

    let position = -2;
    if (this.favourites !== null) {
      position = this.favourites.findIndex((itm) => ((itm.code === item.code) && (itm.store_id === item.store_id) && (itm.record_type === item.record_type)) );
    }

    if ((item.isFavourite) && (position < 0)) {
      this.favourites.push(item);
    } else if ((!item.isFavourite) && (position > -1)) {
      this.favourites.splice(position, 1);
    }

    localStorage.removeItem('favourites');
    localStorage.setItem('favourites', JSON.stringify(this.favourites));
  }

  bigScreen() {
    return window.innerWidth > 750;
  }

  getAssistantDropdowns() {
    let endpoint = this.apiUrl + 'material_assistant';
    let requestOptions = this.getRequestOptions();
    let payload = {
      action: 'get-dropdowns'
    }

    return new Promise(resolve => {
      this.http.post(endpoint, payload, requestOptions).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });

  }

  getAssistantMaterials(materialType: string, color: string, usage: string, finishing: string, countOnly: boolean) {
    let endpoint = this.apiUrl + 'material_assistant';
    let requestOptions = this.getRequestOptions();
    let payload = JSON.stringify(
    {
      action: 'get-materials',
      type: materialType,
      color: color,
      usage: usage,
      finishing: finishing,
      countOnly: countOnly
    });

    return new Promise(resolve => {
      this.http.post(endpoint, payload, requestOptions).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });

  }

  getAssistantStocks(erpCode: string) {
    let endpoint = this.apiUrl + 'material_assistant';
    let requestOptions = this.getRequestOptions();

    let payload = {
      action: 'get-stocks',
      erpCode: erpCode,
    }

    return new Promise(resolve => {
      this.http.post(endpoint, payload, requestOptions).subscribe(data => {
          resolve(data);
      }, err => {
        console.log(err);
      });
    });

  }

  getRequestOptions() {
    const requestOptions = {
      params: new HttpParams()
    };

    requestOptions.params.set('Accept','application/json');
    requestOptions.params.set('Content-Type','application/json');

    return requestOptions;
  }
}
